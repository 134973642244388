<template>
  <nav>
    <v-app-bar flat app color="#78ade1">

      <!--<v-app-bar-nav-icon class="grey--text"></v-app-bar-nav-icon>-->

      <v-avatar tile size="180" class="mx-5">
        <v-img contain :src="require('@/assets/sf_nav_neg.png')" />
      </v-avatar>

      <v-app-bar-title class="text-uppercase text-no-wrap font-weight-light grey--text text--lighten-4">
        <span>Seafuture 2023</span>
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <v-btn depressed rounded color="grey lighten-4" @click="backToB2B">
        <v-icon left>mdi-account</v-icon>
        B2B Platform
      </v-btn>

    </v-app-bar>

  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  methods: {
    backToB2B() {
      window.open('https://b2b.seafuture.it/', '_blank');
    },
  },
}
</script>

<style>

</style>