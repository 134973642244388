<template>
  <div id="catalog">
    
    <v-card height="" class="ma-6 rounded-xl blue darken-2">
      <v-card-title class="blue darken-2 grey--text text--lighten-4">
        Exhibitors
        <v-spacer></v-spacer>
        <v-select
          class="mt-6 mr-4"
          dark
          v-model="category"
          :items="categories"
          prepend-icon="mdi-file-document"
          item-text="category_name"
          item-value="id"
          label="Category: "
        >
        </v-select>
        <v-text-field
          v-model="searchTerm"
          append-icon="mdi-magnify"
          label="Search"
          dark
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="partnersPerCategory"
        :items-per-page="perPageObjects"
        :loading="isLoading"
        loading-text="Loading ..."
        class="blue lighten-4 elevation-1"
        hide-default-footer
        sort-by="name"
      >
        <template v-slot:item.name="{ item }">  <!-- eslint-disable-line -->
          <router-link
            :to="{ name: 'ExhibitorDetail', params: { pk: `${item.id}` } }"
            class="text-decoration-none grey--text text--darken-4">
          {{ item.name }}</router-link>
        </template>
      </v-data-table>
      <div class="text-center">
        <v-pagination
          v-model="currentPage"
          :length="totPages"
          :total-visible="7"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="getData"
          class="my-2"
        ></v-pagination>
      </div>

    </v-card>
  </div>
</template>

<script>
import { apiServiceAsync, getCustomResponse } from '../commons/apiservice.js';
export default {
  name: "Home",
  data: () => ({
    perPageObjects: 15,
    count: 1,
    currentPage: 1,
    searchTerm: null,
    isLoading: true,
    partners: [],
    categories: [{id: 0, category_name: '--------------------'}],
    category: null,
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'Country', value: 'nation' },
      //{ text: 'Category', value: 'category' },
      { text: 'Website', value: 'website' },
      { text: 'Stand', value: 'stand_number'},
    ],
    //
  }),
  computed: {
    totPages() {
      return Math.ceil(this.count / this.perPageObjects);
    },
    offset() {
      return this.perPageObjects * (this.currentPage - 1)
    },
    limit() {
      return this.perPageObjects;
    },
    endpoint() {
      if (!this.searchTerm) {
        return `partners/?limit=${this.limit}&offset=${this.offset}`;
      } else {
        return `partners/?limit=${this.limit}&offset=${this.offset}&search=${this.searchTerm}`;
      }
    },
    partnersPerCategory() {
      /**************************************************************
       * Filter this.partners based on user selected category.
       * Return the full list if category is None or category.id is 0
       **************************************************************/
      if (this.category === null || this.category === 0) return this.partners;
      return this.partners.filter(partner => partner.categories.includes(this.category));
    }
  },
  methods: {
    setTitle(title) {
      document.title = title;
    },
    async getData() {
      /************************************************************
       * Fetches Partner objects data from backend
       ************************************************************/
      //console.log(this.category);
      let rawResponse = await apiServiceAsync(this.endpoint);
      let res = await getCustomResponse(rawResponse);
      if (res.ok) {
        this.count = res.data.count;
        this.partners = res.data.results;
        this.isLoading = false;
      } else {
        // handle not 2XX statuses
        this.isLoading = false;
      }
    },
    async getCategories() {
      let endpoint = 'categories/?limit=100';
      let rawResponse = await apiServiceAsync(endpoint);
      let res = await getCustomResponse(rawResponse);
      if (res.ok) {
        this.categories.push(...res.data.results);
      } else {
        // handle non 2XX statuses
        console.log(res);
      }
    },
  },
  watch: {
    searchTerm: function(val, oldVal) {
      /**************************************
       * Real time search
       **************************************/
      if (val !== oldVal) {
        this.isLoading = true;
        this.currentPage = 1;
        this.getData();
      }
    }
  },
  created() {
    this.setTitle('Catalog');
    this.getCategories();
    this.getData();
  }
};
</script>


<style>

table tbody tr:hover{
  background-color: #1976d299 !important;
}


</style>