import { CSRF_TOKEN } from "./csrf_token.js";

const API_ROOT = process.env.VUE_APP_API_ROOT;

async function getJSONContent(response) {
  // get a response object and parse its json content

  if (response.status === 204) return "";
  return response.json();
}

async function getCustomResponse(response) {
  return {
    status: response.status,
    ok: response.ok,
    data: await response.json(),
  }
}

function buildHeaders(contentType) {
  /* Since an empty Authorization header will always get a 401
  even on endpoints that do not require authentication, this method
  checks if localstorage has a vald Authorization, in order to determine
  wether the Authorization header should be included in apiService.
  Last, the default content-type header is application/json, but we
  need to modify it when uploading files. So the contentType parameter
  has been included. */

  if (
    localStorage.getItem("Authorization") !== undefined &&
    localStorage.getItem("Authorization") !== null
  ) {
    return {
      "Content-Type": contentType || "application/json",
      "X-CSRF": CSRF_TOKEN,
      Authorization: localStorage.getItem("Authorization"),
    };
  } else {
    return {
      "Content-Type": contentType || "application/json",
      "X-CSRF": CSRF_TOKEN,
    };
  }
}

function apiService(endpoint, method, data, contentType) {
  const config = {
    method: method || "GET",
    body: data !== undefined ? JSON.stringify(data) : null,
    headers:
      contentType !== undefined ? buildHeaders(contentType) : buildHeaders(),
  };
  return fetch(API_ROOT + endpoint, config)
    .then(getJSONContent)
    .catch((error) => console.log(error));
}

async function apiServiceAsync(endpoint, method, data, contentType) {
  const config = {
    method: method || 'GET',
    body: data !== undefined ? JSON.stringify(data) : null,
    headers: contentType !== undefined ?  buildHeaders(contentType) : buildHeaders(),
  };
  return await fetch(API_ROOT + endpoint, config);
}

function fileUploadService(endpoint, method, data) {
  const config = {
    method: method || "POST",
    body: data,
    headers: buildHeaders("multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW")
  };
  delete config.headers['Content-Type'];
  return fetch(API_ROOT + endpoint, config)
    .then(getJSONContent)
    .catch((error) => console.log(error));
}

export { getCustomResponse };
export { apiService };
export { apiServiceAsync };
export { fileUploadService };
