<template>
  <div id="expositor">
    <v-card class="ma-6 rounded-xl" justify="space-around">
      <v-card-title class="blue darken-2 grey--text text--lighten-4 rounded-t-xl">
        <v-avatar size="200" tile>
          <img :src="'https://b2b.seafuture.it/static/logos/partner_' + exhibitor.odoo_id + '.jpg'" alt="Exhibitor">
        </v-avatar>
        <div class="ml-5 align-self-center">
          <h1 class="mb-6">
            {{ exhibitor.name }}
          </h1>
          <div class="text-subtitle-1">
            {{ exhibitor.address }}<br>
            {{ exhibitor.city}}<br>
            {{ exhibitor.nation }}<br>
            {{ exhibitor.website }}<br>
            {{ exhibitor.phone }}<br>
            Stand Number: {{ exhibitor.stand_number }}
          </div>
        </div>
        
        <v-spacer></v-spacer>

        <!--<div class="mr-8 mr-md-16 align-self-center">
          <p class="text-h3">Product</p>
          <v-avatar size="200" tile>
            <img :src="'https://b2b.seafuture.it/static/products/partner_product_' + exhibitor.odoo_id + '.jpg'" alt="Product">
          </v-avatar>
        </div>-->

        <v-btn depressed rounded color="grey lighten-4" class="align-self-start">
          <v-icon left>mdi-arrow-left</v-icon>
          <router-link :to="{ name: 'Home' }" class="text-decoration-none text--grey text--darken-4">Back</router-link>
        </v-btn>
      </v-card-title>
      <v-card-text class=" my-5 align-self-center">
        <v-container>
          <v-row class="mb-2">
            <v-col cols="12" md="8">
              <h2>Description</h2>
              <p class="my-5">
                {{ exhibitor.introduction }}
              </p>
            </v-col>
            <v-col cols="12" md="4">
              <h2 class="ml-8">Product</h2><br>
              <div class="ml-6">
                <v-avatar size="220" tile>
                  <v-img
                    contain
                    :src="'https://b2b.seafuture.it/static/products/partner_product_' + exhibitor.odoo_id + '.jpg'"
                    alt="Product"
                  ></v-img>
                </v-avatar>
              </div>
            </v-col>
          </v-row>

          <v-divider ></v-divider>
          <v-row class="mt-5" v-if="contact !== null && contact !== undefined">
            <v-col cols="12">
              <v-card  elevation="10" v-if="contact.catalog_email">
                <v-card-title class="blue darken-2 grey--text text--lighten-4">
                  Contact
                </v-card-title>
                <v-card-text class="mt-3 grey--text text--darken-4">
                  <span class="overline" alt="Titolo catalogo">
                    {{ contact.catalog_title}}  
                  </span><br>
                  {{ contact.catalog_first_name }} {{ contact.catalog_last_name }}<br>
                  <a :href="`mailto:${contact.contact_one_email}`">{{ contact.catalog_email}}</a>
                </v-card-text>
              </v-card>
            </v-col>
            <!--<v-col cols="12" sm="6" md="3">
              <v-card  elevation="10">
                <v-card-title class="blue darken-2 grey--text text--lighten-4">
                  Contact One
                </v-card-title>
                <v-card-text class="mt-3 grey--text text--darken-4">
                  <span class="overline" alt="Titolo catalogo">
                      {{ contact.contact_one_title }}
                    </span><br>
                  {{ contact.contact_one_first_name }} {{ contact.contact_one_last_name }} Cognome<br>
                  <a :href="`mailto:${contact.contact_one_email}`">{{ contact.contact_one_email}}</a>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-card  elevation="10">
                <v-card-title class="blue darken-2 grey--text text--lighten-4">
                  Contact Two
                </v-card-title>
                <v-card-text class="mt-3 grey--text text--darken-4">
                  <span class="overline" alt="Titolo catalogo">
                      {{ contact.contact_two_title }}
                    </span><br>
                  {{ contact.contact_two_first_name }} {{ contact.contact_two_last_name }} Cognome<br>
                  <a :href="`mailto:${contact.contact_two_email}`">{{ contact.contact_two_email}}</a>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-card  elevation="10" v-if="contact.contact_three_email">
                <v-card-title class="blue darken-2 grey--text text--lighten-4">
                  Contact Three
                </v-card-title>
                <v-card-text class="mt-3 grey--text text--darken-4">
                  <span class="overline" alt="Titolo catalogo">
                      {{ contact.contact_three_title }}
                    </span><br>
                  {{ contact.contact_three_first_name }} {{ contact.contact_three_last_name }} <br>
                  <a :href="`mailto:${contact.contact_three_email}`">{{ contact.contact_three_email}}</a>
                </v-card-text>
              </v-card>
            </v-col>-->

          </v-row>

          <v-row>
            <v-col cols="12">
                <v-card
                  elavation="10"
                >
                  <v-card-title class="blue darken-2 grey--text text--lighten-4">
                    Categories
                  </v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item v-for="(item, i) in categories" :key="i">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

    </v-card>
  </div>
</template>

<script>
import { apiServiceAsync, getCustomResponse } from '../commons/apiservice';
export default {
  name: "ExhibitorDetail",

  props: {
    pk: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    exhibitor: {},
    contacts: [],
    categories: [],
  }),

  computed: {
    contact() {
      /**
       * Contacts can be multiples, but we always want
       * the first one.
       */
      return this.contacts.length > 0 ? this.contacts[0] : null;
    },
  },

  methods: {
    setTitle(title) {
      document.title =title;
    },

    async getCategory(id) {
      let endpoint = `categories/${id}/`;
      let rawResponse = await apiServiceAsync(endpoint);
      let res = await getCustomResponse(rawResponse);
      if (res.ok) {
        return res.data;
      } else {
        return {};
      }
    },

    async getCategories() {
      for (const c of this.exhibitor.categories) {
        let category = await this.getCategory(c);
        this.categories.push({
          title: category.category_name,
          value: category.id,
        });
      }
    },

    async getExhibitor() {
      /**
       * Call the server to take the current exhibitor.
       * We use pk from the url.
       */
      let endpoint = `partners/${this.pk}/`;
      let rawResponse = await apiServiceAsync(endpoint);
      let res = await getCustomResponse(rawResponse);
      if (res.ok) {
        this.exhibitor = res.data;
        this.setTitle(res.data.name);
      } else {
        // gestire l'errore
      }
    },

    async getContacts() {
      /**
       * Get all contacts of the current exhibitor.
       * We always want the first one, but we filter
       * it using a computed.
       */
      let endpoint = `partners/${this.pk}/contacts`;
      let rawResponse = await apiServiceAsync(endpoint);
      let res = await getCustomResponse(rawResponse);
      if (res.ok) {
        console.log(res.data);
        this.contacts = res.data.results;
      } else {
        // gestire l'errore
      }
    },
  },

  created() {
    this.getContacts();
    this.getExhibitor().then(() => {
      this.getCategories();
    })
  },
}
</script>

<style>

</style>